import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import { Box, Typography, Grid, Button } from "@mui/material"

import Card from "../card"
import { RichText } from "../contentful"
import QuotesAndTestmonials from "../quotes-and-testimonials"

export const AllTopics = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulTopics(filter: { publishOnWebsite: { eq: true } }) {
            nodes {
              slug
              name
              coverImage {
                filename
                file {
                  url
                  fileName
                }
                url
              }
              icon {
                filename
                gatsbyImageData
                url
                file {
                  url
                  fileName
                }
              }
              description {
                raw
              }
            }
          }
        }
      `}
      render={({ allContentfulTopics }) => {
        const data = allContentfulTopics?.nodes ?? []
        const [shownData, setShownData] = useState(24)
        if (!data.length) {
          return <span />
        }
        return (
          <>
            <Box
              className="container"
              paddingTop={{
                xs: "10px",
                md: "60px",
              }}
              paddingBottom={{
                xs: "40px",
                md: "90px",
              }}
            >
              <Typography
                variant="h3"
                marginBottom={{
                  md: "55px",
                  xs: "30px",
                }}
              >
                All Topics
              </Typography>
              <Grid container spacing={4}>
                {data.slice(0, 12).map(({ icon, description, name, slug }) => (
                  <Grid item xs={12} md={3}>
                    <Card
                      title={name}
                      icon={icon?.url}
                      content={
                        <RichText content={description} variant="body3" />
                      }
                      linkUrl={`/${slug}`}
                      linkLabel="Read More"
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box
              margin={{
                xs: "0px -20px",
                md: "0px 20px",
              }}
            >
              <QuotesAndTestmonials
                data={{
                  testimonials: [
                    {
                      description:
                        "“Confidant Health has made my getting my meds easier and more efficient. Im’ able to get all my med details on my dashboard, submit refills, and so much more.”",
                      name: "Real People, Real Solutions",
                      id: "1",
                    },
                    {
                      description:
                        "“Confidant Health has made my getting my meds easier and more efficient. Im’ able to get all my med details on my dashboard, submit refills, and so much more.”",
                      name: "Real People, Real Solutions",
                      id: "2",
                    },
                    {
                      description:
                        "“Confidant Health has made my getting my meds easier and more efficient. Im’ able to get all my med details on my dashboard, submit refills, and so much more.”",
                      name: "Real People, Real Solutions",
                      id: "3",
                    },
                  ],
                }}
              />
            </Box>
            <Box
              className="container"
              paddingTop={{
                xs: "10px",
                md: "60px",
              }}
            >
              <Grid container spacing={4}>
                {data
                  .slice(12, shownData)
                  .map(({ icon, description, name, slug }) => (
                    <Grid item xs={12} md={3}>
                      <Card
                        title={name}
                        icon={icon?.url}
                        content={
                          <RichText content={description} variant="body3" />
                        }
                        linkUrl={`/${slug}`}
                        linkLabel="Read More"
                      />
                    </Grid>
                  ))}
              </Grid>
              {data.length > shownData && (
                <Box
                  display="flex"
                  marginTop={{
                    md: "55px",
                    xs: "30px",
                  }}
                  justifyContent="center"
                  onClick={() => setShownData(shownData + 12)}
                >
                  <Button color="gray">Show More</Button>
                </Box>
              )}
            </Box>
          </>
        )
      }}
    />
  )
}
