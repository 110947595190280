import React, { Component } from "react"
import { ThemeProvider } from "@mui/material/styles"
import { Divider, Box, Typography, Link } from "@mui/material"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Articles } from "../components/learning-library/articles"
import { AllTopics } from "../components/learning-library/all-topics"
import { SegmentAnalytics } from "../common/utils/SegmentAnalytics"
import { SegmentEvents } from "../common/utils/SegmentSpec"
import { isValidPhone } from "../common/utils/CommonUtils"
import { BranchUtils } from "../common/utils/BranchUtils"
import TextAndSearch from "../components/text-and-search"
import CTA from "../components/cta"
import theme from "../theme"

class LearningLibrary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      contentLoaded: false,
      SearchedArticles: [],
      questions: [],
      searchLibraryValue: "",
      Searched: false,
      phone: "",
      success: false,
      loader: {
        options: {
          position: "absolute",
        },
      },
    }
  }

  componentDidMount() {
    window.analytics.page("LearningLibrary")
    this.setState({
      contentLoaded: false,
    })
  }

  sendPhone(phoneNumber) {
    if (!this.state.error) {
      const phone = isValidPhone(phoneNumber)
      if (phone) {
        SegmentAnalytics.track(
          SegmentEvents.USER_REQUESTED_FOR_APP_FROM_LEARNING_LIBRARY_PAGE,
          {
            phone,
            appRequested: true,
          }
        )

        let fromPage = "Learning-Library"
        BranchUtils.desktopSendSMS(phone, fromPage, "Bottom CTA", fromPage)

        this.setState({
          success: true,
        })
      } else {
        this.setState({
          error: true,
          success: false,
          phone: "",
        })
      }
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <SEO title="Learning Library" />
        <Layout location={this.props.location}>
          <TextAndSearch
            title="Long headline that connects with subscribers"
            subtitle="Learning Library"
            placeholder="What can we help you find today?"
            label="Popular links: Addiction, Suboxone, Introduction to Confidant"
            backgroundColor="tan.main"
          />
          <Box padding="40px 0px" backgroundColor="primary.main">
            <Box className="container">
              <Typography variant="h4" color="#FFF" textAlign="center">
                Ready to join our community? Get the app.
                <Link href="#download-the-app">
                  <svg
                    width="33"
                    height="12"
                    viewBox="0 0 33 12"
                    fill="none"
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <path
                      d="M29.6713 5.05264H0.974979C0.436562 5.05264 0 5.47683 0 6C0 6.52317 0.436562 6.94736 0.974979 6.94736H29.6713L26.1356 10.3826C25.755 10.7527 25.755 11.3526 26.1356 11.7225C26.5165 12.0925 27.1337 12.0925 27.5145 11.7225L32.7144 6.66992C33.0952 6.29986 33.0952 5.70015 32.7144 5.33008L27.5145 0.277546C27.1337 -0.0925154 26.5165 -0.0925154 26.1356 0.277546C25.755 0.647397 25.755 1.24732 26.1356 1.61739L29.6713 5.05264Z"
                      fill="#30C06A"
                    />
                  </svg>
                </Link>
              </Typography>
            </Box>
          </Box>
          <div className="section-padding">
            <Articles />
            <Box marginTop="30px" marginBottom="30px" className="container">
              <Divider />
            </Box>
            <AllTopics />
          </div>
          <CTA />
        </Layout>
      </ThemeProvider>
    )
  }
}

export default LearningLibrary
