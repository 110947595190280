import React from "react"
import {
  Box,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Link,
} from "@mui/material"

import Image from "../Image"

const imageUrl =
  "https://images.ctfassets.net/ewql5j7ukokq/5c623ZK7X6asPctSC3n4Sz/42058e7f230b875e6d65872736624ace/Screen_Shot_2022-08-27_at_7.46.58_AM.png"
const googleIcon =
  "https://images.ctfassets.net/ewql5j7ukokq/2Pdgl9EnvhbdPM9iomwgkd/d59751cb4fd85a18994dfb2f377caa09/Google_Play.png?w=124&h=38&q=50&fm=png"
const appleIcon =
  "https://images.ctfassets.net/ewql5j7ukokq/4hllx0LHb0MbfSMgroWUES/05351b2357c2a9aaba15659344fb2002/App_Store.png?w=124&h=38&q=50&fm=webp"
const googleAppLink =
  "https://play.google.com/store/apps/details?id=live.safeopioidusecoalition.confidant&hl=en_US&gl=US"
const appleAppLink =
  "https://apps.apple.com/us/app/confidant-healthy-habits/id1478727129"
export default function CTA() {
  return (
    <Box
      id="download-the-app"
      backgroundColor="info.main"
      className="section-padding"
    >
      <Box className="container">
        <Grid
          container
          spacing={{
            xs: 5,
            md: 15,
          }}
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <Image src={imageUrl} style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography color="#FFF" variant="subtitle1">
              download app
            </Typography>
            <Typography variant="h1" component="h2" marginBottom="16px">
              We work around your schedule and so do our partners.
            </Typography>
            <Box display="flex" flexDirection="column">
              <TextField
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                placeholder="Share your phone number to download"
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ cursor: "pointer" }} position="end">
                      <svg
                        width="27"
                        height="26"
                        viewBox="0 0 27 26"
                        fill="none"
                      >
                        <path
                          d="M13.5 1.95003C19.6036 1.95003 24.55 6.89624 24.55 13C24.55 19.1036 19.6038 24.05 13.5 24.05C7.39641 24.05 2.45003 19.1038 2.45003 13C2.45003 6.89641 7.39625 1.95003 13.5 1.95003ZM13.5 0C6.31889 0 0.5 5.82 0.5 13C0.5 20.1811 6.32 26 13.5 26C20.6811 26 26.5 20.18 26.5 13C26.5 5.81888 20.68 0 13.5 0Z"
                          fill="#3B91EA"
                        />
                        <path
                          d="M16.1713 12.0526H7.47498C6.93656 12.0526 6.5 12.4768 6.5 13C6.5 13.5232 6.93656 13.9474 7.47498 13.9474H16.1713L12.6356 17.3826C12.255 17.7527 12.255 18.3526 12.6356 18.7225C13.0165 19.0925 13.6337 19.0925 14.0145 18.7225L19.2144 13.6699C19.5952 13.2999 19.5952 12.7001 19.2144 12.3301L14.0145 7.27755C13.6337 6.90748 13.0165 6.90748 12.6356 7.27755C12.255 7.6474 12.255 8.24732 12.6356 8.61739L16.1713 12.0526Z"
                          fill="#3B91EA"
                        />
                      </svg>
                    </InputAdornment>
                  ),
                  sx: {
                    fontSize: "13px",
                    backgroundColor: "#F9F5EA",
                    ".MuiInputBase-input": {
                      "&::placeholder": {
                        color: "#434E57",
                      },
                    },
                  },
                }}
              />
              <Typography
                marginTop="16px"
                variant="body4"
                color="gray.contrastText"
              >
                Trust us, we won’t spam you with nonsense.
              </Typography>
            </Box>
            <Box marginTop="30px" />
            <Grid container spacing={5}>
              <Grid item>
                <Link href={googleAppLink}>
                  <Image src={googleIcon} />
                </Link>
              </Grid>
              <Grid item>
                <Link href={appleAppLink}>
                  <Image src={appleIcon} />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
