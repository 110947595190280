import React from "react"
import { Box, Grid, Typography } from "@mui/material"
import { graphql, StaticQuery } from "gatsby"
import Loader from "react-loader"

import Card from "../card"
export const Articles = () => {
  return (
    <StaticQuery
      query={graphql`
        query FeaturedArticles {
          allContentfulEducationalContent(
            limit: 3
            filter: { publishOnWebsite: { eq: true }, isPopular: { eq: true } }
          ) {
            nodes {
              id
              titleImage {
                filename
                url
                file {
                  url
                  fileName
                }
              }
              title {
                title
              }
              description
              slug
              topics {
                slug
              }
            }
          }
        }
      `}
      render={({ allContentfulEducationalContent }) => {
        const data = allContentfulEducationalContent?.nodes
        return (
          <Box className="container">
            <Typography
              marginBottom={{
                md: "55px",
                xs: "30px",
              }}
              variant="h3"
            >
              Featured Articles
            </Typography>
            {data ? (
              data.length > 0 ? (
                <Grid container spacing={4}>
                  {data.map((item) => {
                    const topic =
                      item.topics && item.topics.length ? item.topics[0] : {}
                    return (
                      <Grid item xs={12} md={4} key={item.id}>
                        <Card
                          image={item?.titleImage?.file?.url}
                          content={item.description}
                          title={item.title.title}
                          linkLabel="Read More"
                          linkUrl={`/${topic.slug ?? ""}/${item.slug}/`}
                          bodyVariant="body3"
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              ) : (
                <p>No Featured Articles Found</p>
              )
            ) : (
              <Loader loaded={false} />
            )}
          </Box>
        )
      }}
    />
  )
}
